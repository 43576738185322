import { useSession } from 'lib/session-client'
import { useEffect, useState } from 'react'
import { UserProfile } from 'types'
import { getUserProfile } from 'data/authentication'

type UseUserProfile = {
  userProfile: UserProfile | null
  redirect: boolean | null | undefined
}

export const useUserProfile = (): UseUserProfile => {
  const [session, loading] = useSession()
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null)
  useEffect(() => {
    if (session?.accessToken) {
      getUserProfile(session?.accessToken).then((data) => setUserProfile(data))
    }
  }, [session])

  return {
    userProfile: loading ? null : userProfile,
    redirect: !session && !loading
  }
}
