import(/* webpackMode: "eager", webpackExports: ["default"] */ "/jenkins/workspace/handball.net_production@2/web/components/layout/NewAppUpdateInfo.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/jenkins/workspace/handball.net_production@2/web/components/layout/NewHeaderMenuButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/jenkins/workspace/handball.net_production@2/web/components/layout/NewHeaderSearchField.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/jenkins/workspace/handball.net_production@2/web/components/layout/NewHeaderUserInfo.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/jenkins/workspace/handball.net_production@2/web/components/layout/NewMenu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/jenkins/workspace/handball.net_production@2/web/components/layout/NewScrollToTop.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NewTabNavigationItem"] */ "/jenkins/workspace/handball.net_production@2/web/components/layout/NewTabNavigationItem.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/jenkins/workspace/handball.net_production@2/web/components/layout/SpecialLink.tsx");
;
import(/* webpackMode: "eager" */ "/jenkins/workspace/handball.net_production@2/web/components/router/Link.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/jenkins/workspace/handball.net_production@2/web/context/AdRouteContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MenuProvider"] */ "/jenkins/workspace/handball.net_production@2/web/context/MenuContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AppConfigProvider"] */ "/jenkins/workspace/handball.net_production@2/web/data/config/app_config.tsx");
;
import(/* webpackMode: "eager" */ "/jenkins/workspace/handball.net_production@2/web/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/jenkins/workspace/handball.net_production@2/web/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/jenkins/workspace/handball.net_production@2/web/node_modules/nextjs-toploader/dist/index.js");
;
import(/* webpackMode: "eager" */ "/jenkins/workspace/handball.net_production@2/web/styles/globals.sass");
